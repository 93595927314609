
<template>
  <div class="echarts-box">
    <div id="container1"></div>
  </div>
</template>

<script>
import * as echarts from 'echarts';


export default {
  name: "HelloWorld",
  props: {
    msg: String
  },
  data(){
    return {
      data: [],
      xData: [],
    }
  },  
  mounted() {
    // this.moreChart();
  },
  methods: {
    moreChart() {
      var chartDom = document.getElementById('container1');
        var myChart = echarts.init(chartDom);
        var option;
        // var yStandard = [200, 220,210,300,280,310,320,300,350,290]; //标准
        option = {
             xAxis: {
    scale: true
  },
  grid: {
      left: 60,
      top: 10,
      bottom: 5,
      right: 40
  },
  yAxis: {
    scale: true,
    axisLabel: {
      textStyle: {
          color: '#CCCCCC',
          fontSize: 14
      },
    },
  },
  xAxis: {
      data: this.xData,

      axisLabel: {
          show: false,
          textStyle: {
              color: '#CCCCCC',
              fontSize: 14
          },
      },


  },
  series: this.data
      };
        option && myChart.setOption(option);
    }
  }
};
</script>

<style lang="scss"  scoped>

    .charts-box, #container1 {
        width: 100%;
        height: 200px;
    }

    
</style>