<template>
    <div class="parentBoxCls">
        <div class="mainBoxCls">
            <div class="leftMainCls">
                <div class="dataBoxCls" style="height: 472px;">
                    <div class="jiaoCls jiao1Cls"></div>
                    <div class="jiaoCls jiao2Cls"></div>
                    <div class="jiaoCls jiao3Cls"></div>
                    <div class="jiaoCls jiao4Cls"></div>
                    <div class="titleJtCls">
                        <div class="tbttsCls">
                            <img src="../../assets/images/dataScreen/qiranChickenEggRoom/titlelbg.png">
                            蛋鸡产蛋统计数据
                            <img src="../../assets/images/dataScreen/qiranChickenEggRoom/titlerbg.png">
                        </div>
                    </div>
                    <div class="tcnrCls">
                        <!--填充内容-->
                      <Highecharts1 ref="chilkenEgg"></Highecharts1>
           
                    </div>
                </div>
                <div class="dataBoxCls" style="height: 453px;margin-top: 20px;">
                    <div class="jiaoCls jiao1Cls"></div>
                    <div class="jiaoCls jiao2Cls"></div>
                    <div class="jiaoCls jiao3Cls"></div>
                    <div class="jiaoCls jiao4Cls"></div>
                    <div class="titleJtCls">
                        <div class="tbttsCls">
                            <img src="../../assets/images/dataScreen/qiranChickenEggRoom/titlelbg.png">
                            喂养数据统计
                            <img src="../../assets/images/dataScreen/qiranChickenEggRoom/titlerbg.png">
                        </div>
                    </div>
                    <div class="tcnrsCls" style="height:404px">
                        <div class="qnklxhlCls">全年口粮消耗量统计</div>
                        <div class="qnklxhlDetailCls">
                            <div class="qnklxhlDetailBgCls">
                                <div>{{kouliang.cornnum  ? bllw(kouliang.cornnum) : '0.00'}}<span>公斤</span></div>
                                <div>玉米（63%）</div>
                            </div>
                            <div class="qnklxhlDetailBgCls">
                                <div>{{kouliang.pulpnum ? bllw(kouliang.pulpnum) : '0.00'}}<span>公斤</span></div>
                                <div>豆粕（19%）</div>
                            </div>
                            <div class="qnklxhlDetailBgCls">
                                <div>{{kouliang.stonenum ? bllw(kouliang.stonenum) : '0.00'}}<span>公斤</span></div>
                                <div>贝壳粉（8%）</div>
                            </div>
                            <div class="qnklxhlDetailBgCls qnklxhlDetailBgCls2">
                                <div style="padding-top:5px;">{{kouliang.pabulumnum ? bllw(kouliang.pabulumnum) : '0.00'}}<span>公斤</span></div>
                                <div style="padding-top:0px;">营养添加（10%）</div>
                                <div style="padding-top:5px;color:#C2C6CD;">棉粕&nbsp;&nbsp;豆油&nbsp;&nbsp;盐&nbsp;&nbsp;氨基酸&nbsp;&nbsp;维生素&nbsp;&nbsp;矿物质元素</div>
                            </div>
                            <div class="qnklxhlDetailBgCls">
                                <div>适时适量</div>
                                <div>青菜</div>
                            </div>
                            <!--<div class="qnklxhlDetailBgCls">
                                <div>620<span>Kg</span></div>
                                <div>大豆油</div>
                            </div>
                            <div class="qnklxhlDetailBgCls">
                                <div>620<span>Kg</span></div>
                                <div>小苏打</div>
                            </div>
                            <div class="qnklxhlDetailBgCls">
                                <div>620<span>Kg</span></div>
                                <div>食盐</div>
                            </div>
                            <div class="qnklxhlDetailBgCls">
                                <div>620<span>Kg</span></div>
                                <div>核心料(预混料)</div>
                            </div>
                            <div class="qnklxhlDetailBgCls">
                                <div>620<span>Kg</span></div>
                                <div>玉米蛋白粉</div>
                            </div>-->
                        </div>
                        <div class="qnklxhlCls">喂养时间统计</div>
                        <div class="ywsjtjBoxCls">
                            <div class="timerBarCls">
                                <span>03:00</span>
                                <span>06:00</span>
                                <span>09:00</span>
                                <span>12:00</span>
                                <span>15:00</span>
                                <span>18:00</span>
                                <span>21:00</span>
                                <span>24:00</span>
                            </div>
                            <template v-for="itemRd in this.wyList">
                                <div :style="{ 'left': weiyangTimeCompute(itemRd.feedAllTime) + 'px' }" class="weishiRecordCls">
                                    已喂食{{ itemRd.feedAllTime }}</div>
                            </template>
                        </div>
                        <div class="dayBarCls" style="margin-top:60px;">
                            <div v-for="(dayItem,index) in this.daytimeArr" :class="dayItem == currDay ? 'seled' : ''"
                                @click="changeWeiyangDay(dayItem, index)">{{ dayItem }}日</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="midMainCls">
                <el-carousel indicator-position="none" height="200px">
                    <el-carousel-item>
                        <div class="paihangbanCls">
                            <div class="ban1Cls">
                                <div class="huanFntCls">{{jihuizong.totalarea || 0}}</div>
                                <div>总占地(亩)</div>
                            </div>
                            <div class="ban2Cls">
                                <div class="lanFntCls">{{parseInt(jihuizong.fedeggs) || 0}}</div>
                                <div>喂养蛋鸡(只)</div>
                            </div>
                            <div class="ban3Cls">
                                <div class="lanFntCls">{{parseInt(jihuizong.onegg) || 0}}</div>
                                <div>已产鸡蛋(颗)</div>
                            </div>
                            <div class="ban4Cls">
                                <div class="huanFntCls">{{parseInt(jihuizong.aveegg) || 0}}</div>
                                <div>日均产蛋(颗)</div>
                            </div>
                        </div>
                    </el-carousel-item>
                    <el-carousel-item>
                        <div class="paihangbanCls">
                            <div class="iot1">
                                <div class="huanFntCls">{{waibuiot.temperature != 0 ? parseInt(waibuiot.temperature / 10) : '--'}}°C</div>
                                <div>温度</div>
                            </div>
                            <div class="iot2">
                                <div class="lanFntCls">{{waibuiot.humidity != 0 ? (Number(waibuiot.humidity)).toFixed(0) : '--'}}%Rh</div>
                                <div>湿度</div>
                            </div>
                            <div class="iot3">
                                <div class="lanFntCls">{{waibuiot.carbonDioxide != 0 ? waibuiot.carbonDioxide : '--'}}ppm</div>
                                <div>二氧化碳</div>
                            </div>
                            <div class="iot4">
                                <div class="huanFntCls">{{waibuiot.ammonia != 0 ? waibuiot.ammonia : '--'}}ppm</div>
                                <div>氨气</div>
                            </div>
                        </div>
                    </el-carousel-item>
                </el-carousel>
                <div class="xuanchuanshipinCls">
                    <video class="video" style=" object-fit: fill" muted src="../../assets/images/dataScreen/js.mp4"
                        autoplay loop></video>
                </div>
                <div class="dataBoxCls" style="height: 299px;margin-top: 20px;">
                    <div class="jiaoCls jiao1Cls"></div>
                    <div class="jiaoCls jiao2Cls"></div>
                    <div class="jiaoCls jiao3Cls"></div>
                    <div class="jiaoCls jiao4Cls"></div>
                    <div class="titleJtCls">
                        <div class="tbttsCls">
                            <img src="../../assets/images/dataScreen/qiranChickenEggRoom/titlelbg.png"></img>
                            蛋鸡实时AIOT智能脚环数据
                            <img src="../../assets/images/dataScreen/qiranChickenEggRoom/titlerbg.png"></img>
                        </div>
                    </div>
                    <div class="tcnrsCls" style="height:250px">
                        <Highecharts2 ref="jiFoot"></Highecharts2>
                        <div class="dayBarCls" style="width: 690px">
                            <div v-for="(dayItem,index) in this.daytimeArr" :key="index" :class="dayItem == currDay1 ? 'seled' : ''"
                                @click="changeWeiyangDay1(dayItem,index)">{{ dayItem }}日</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="rightMainCls">
                <div class="dataBoxCls" style="height: 302px;margin-top: -4px;">
                    <div class="jiaoCls jiao1Cls"></div>
                    <div class="jiaoCls jiao2Cls"></div>
                    <div class="jiaoCls jiao3Cls"></div>
                    <div class="jiaoCls jiao4Cls"></div>
                    <div class="titleJtCls">
                        <div class="tbttsCls">
                            <img src="../../assets/images/dataScreen/qiranChickenEggRoom/titlelbg.png"></img>
                            实时气象监测数据
                            <img src="../../assets/images/dataScreen/qiranChickenEggRoom/titlerbg.png"></img>
                        </div>
                    </div>
                    <div class="tcnrsCls" style="height:253px">
                        <div class="wulianwangBoxCls">
                            <div class="wenduBoxCls">{{parseInt(qxList.iotDataBO.ambientTemperature)}}</div>
                            <div class="wulianwangValueBoxCls">
                                <div><span>风速</span><span>{{qxList.iotDataBO.windSpeed}}m/s</span></div>
                                <div><span>湿度</span><span>{{Number(qxList.iotDataBO.ambientHumidity).toFixed(0)}}%Rh</span></div>
                                <div><span>雨量</span><span>{{qxList.iotDataBO.rainfall}}mm</span></div>
                                <div><span>辐射</span><span>{{qxList.iotDataBO.totalRadiation ? qxList.iotDataBO.totalRadiation : 0}}ppm</span></div>
                                <div><span>光照</span><span>{{qxList.iotDataBO.lightIntensity}}kLux</span></div>
                                <div><span>大气压</span><span>{{qxList.iotDataBO.pressure}}hPa</span></div>
                            </div>
                        </div>
                        <div style="height: 30px; width: 100%; float: left;"></div>
                        <div class="dayBarCls">
                            <div v-for="(dayItem,index) in this.daytimeArr" :key="index" :class="dayItem == currDay2 ? 'seled' : ''"
                                @click="changeWeiyangDay2(dayItem,index)">{{ dayItem }}日</div>
                        </div>

                    </div>
                </div>

                <div class="dataBoxCls" style="height: 306px;margin-top: 20px;">
                    <div class="jiaoCls jiao1Cls"></div>
                    <div class="jiaoCls jiao2Cls"></div>
                    <div class="jiaoCls jiao3Cls"></div>
                    <div class="jiaoCls jiao4Cls"></div>
                    <div class="titleJtCls">
                        <div class="tbttsCls">
                            <img src="../../assets/images/dataScreen/qiranChickenEggRoom/titlelbg.png"></img>
                            疾病监测预警
                            <img src="../../assets/images/dataScreen/qiranChickenEggRoom/titlerbg.png"></img>
                        </div>
                    </div>
                    <div class="tcnrsCls pt10" style="height:257px">
                        <div class="flex f_jc_e f_ai_c pb10">
                        <img class="echarts-icon" src="../../assets/images/dataScreen/egg/icon1.png" alt="">
                        <div class="fs14 mr12 ml4" style="color: #fff">已打</div>
                        <img class="echarts-icon" src="../../assets/images/dataScreen/egg/icon2.png" alt="">
                        <div class="fs14 mr12 ml4" style="color: #fff">预警</div>
                        <img class="echarts-icon" src="../../assets/images/dataScreen/egg/icon3.png" alt="">
                        <div class="fs14 mr12 ml4" style="color: #fff">治疗</div>
                    </div>
                    <div class="flex ml20">
                        <!-- <div>
                            <div class="grid-title">下旬</div>
                            <div class="grid-title">中旬</div>
                            <div class="grid-title">上旬</div>
                        </div> -->
                        <div class="flex" style="color: #fff">
                            <div v-for="(item, i) in listMonth" :key="i">
                                <div class="grid flex f_ai_c f_jc_c ta_c">
                                    <div>
                                        <div v-for="(subItem, j) in item.children" :key="j">
                                            <div v-if="subItem.type == '1'" v-for="(itm,inx) in subItem.name" :key="inx" class="disease-text disease-text-1">{{itm}}</div>
                                            <div v-if="subItem.type == '3'" class="disease-text disease-text-2">{{subItem.name}}</div>
                                            <img v-if="subItem.type == '2'" class="treatment-icon" src="../../assets/images/dataScreen/egg/icon3.png" alt="">
                                        </div>
                                    </div>
                                </div>
                                
                                <div class="grid-date">{{item.month}}月</div>
                            </div>
                            
                        </div>
                    </div>
                    </div>
                </div>

                <div class="dataBoxCls" style="height: 300px;margin-top: 20px;">
                    <div class="jiaoCls jiao1Cls"></div>
                    <div class="jiaoCls jiao2Cls"></div>
                    <div class="jiaoCls jiao3Cls"></div>
                    <div class="jiaoCls jiao4Cls"></div>
                    <div class="titleJtCls">
                        <div class="tbttsCls">
                            <img src="../../assets/images/dataScreen/qiranChickenEggRoom/titlelbg.png"></img>
                            鸡舍内外实时监控
                            <img src="../../assets/images/dataScreen/qiranChickenEggRoom/titlerbg.png"></img>
                        </div>
                    </div>
                    <div class="tcnrsCls" style="height:251px">
                        <div class="vedioRowCls" v-if="isTrue">
                            <div  v-for="(item, i) in jiData.deviceEnterpriseRelationBOS2" :key="i">
                                <EZUIKitJs v-if="item.deviceFactory == 'HK'" :msg="'smallvideo'+(i+1)" :attribute="jisheEnvironmentalAttribute" :flv="item.deviceUrl" />
                                <EZUIKitJs2 v-if="item.deviceFactory == 'DH'" :msg="'smallvideo'+(i+1)"  :attribute="jisheEnvironmentalAttribute" :flv="item.deviceUrl"/>
                                
                                <!-- <EZUIKitJs msg="video1" :attribute="jisheEnvironmentalAttribute"
                                    :flv="jiData.qiranJidiInoutRoomVedio1Url" /> -->
                                <div class="vedioColTitleCls"
                                @click="transitionFun(item.deviceUrl, item.installArea)">{{item.installArea}}</div>
                            </div>
                            <!--<div>
                                <EZUIKitJs msg="video1" :attribute="jisheEnvironmentalAttribute"
                                    :flv="jiData.qiranJidiInoutRoomVedio1Url" />
                                <div class="vedioColTitleCls"
                                    @click="transitionFun(jiData.qiranJidiInoutRoomVedio1Url, '#20220001北面')">{{jiData.qiranJidiInoutRoomVedio1Title}}</div>
                            </div>
                            <div>
                                <EZUIKitJs msg="video2" :attribute="jisheEnvironmentalAttribute"
                                    :flv="jiData.qiranJidiInoutRoomVedio2Url" />
                                <div class="vedioColTitleCls"
                                    @click="transitionFun(jiData.qiranJidiInoutRoomVedio2Url, '#20220001北面')">{{jiData.qiranJidiInoutRoomVedio2Title}}</div>
                            </div>-->
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <div class="buttonBoxCls"></div>
    </div>

</template>

<script>
import Highecharts1 from './highecharts/smallChicken/highecharts1.vue'
import Highecharts2 from './highecharts/smallChicken/highecharts2.vue'
import EZUIKitJs from '../../components/EZUIKitJs.vue'
export default {
    name: '',
    components: {
        EZUIKitJs,
        Highecharts1,
        Highecharts2
    },
    computed: {
        weiyangTimeCompute() {
            return (_timeFmt) => {
                var rds = _timeFmt.split(":");
                var fenCounts = parseInt(rds[0]) * 60 + parseInt(rds[1]);

                //left: 13501px;
                // console.log(fenCounts/1440*1440);
                return fenCounts / 1440 * 480 - 32;
            }
        }
    },
    data() {
        return {
            jiData: {},
            iotObj: {},
            weiyangRecordList: [],
            day7List: [],
            day7List1: [],
            qxList: {},
            day7List2: [],
            kouliang: {},
            wyList: [],
            listMonth: [],
            jihuizong: {},
            daytimeArr: [],
            dateList: [],
            waibuiot: {},
            jbList: [],
            bsList: [],
            currDay: 0,
            currDay1: 0,
            currDay2: 0,
            isTrue: false,
            jisheEnvironmentalAttribute:{
                width: 230,
                height: 220
            },
            environmentalAttribute: {
                width: 675,
                height: 610
            },
            show: false,

        }
    },

    mounted() {
        this.init();
        this.GetTime();
        //获取喂养时间数据
        this.getWeiyangRecords();
        //获取前7日
        // this.getday7List();
    },
    methods: {
        init(){
            this.qa.eggsLookeggListLed({}).then(res => {
                setTimeout(() => {
                    console.log(this.$refs.chilkenEgg);
                    let temperatureArr = res.rows;
                    let dateArr = [];
                    temperatureArr.forEach((item,index) => {
                        dateArr.push(item.lookeggTime.substring(0,10));
                    });
                    console.log(dateArr)
                    this.qa.vervelTjListLed({}).then(res => {
                        console.log(res)
                        if (res.rows && res.rows.length > 0) {
                            temperatureArr.forEach((item,index) => {
                                res.rows.forEach((items,idx) => {
                                    if (item.lookeggTime.substring(0,10) == items.syntime) {
                                        item.avesteps = items.avesteps;
                                    }
                                })
                            })
                        } else {
                            temperatureArr.forEach((item,index) => {
                                item.avesteps = 0;
                            });
                        }
                        console.log(temperatureArr)                        
                        this.qa.queryMaxAndMinTemperatureData(dateArr).then(res => {
                            console.log(res)
                            if (res.data.length && res.data.length > 0) {
                                temperatureArr.forEach((item,index) => {
                                    res.data.forEach((items,idx) => {
                                        if (item.lookeggTime.substring(0,10) == items.date) {
                                            item.minAmbientTemperature = items.minAmbientTemperature;
                                            item.maxAmbientTemperature = items.maxAmbientTemperature;
                                        }
                                    })
                                })
                                this.$refs.chilkenEgg.datalist = temperatureArr;
                                console.log(temperatureArr)                            
                                this.$refs.chilkenEgg.moreChart();
                            } else {
                                temperatureArr.forEach((item,index) => {
                                    item.minAmbientTemperature = '';
                                    item.maxAmbientTemperature = '';
                                });
                                this.$refs.chilkenEgg.datalist = temperatureArr;
                                console.log(temperatureArr)                                                        
                                this.$refs.chilkenEgg.moreChart();
                            }
                        })
                    })
                }, 1000);
            })
        

                var data = new Date();
                var year = data.getFullYear();
                // var day = data.getDay();
                // data.setMonth(data.getMonth()+1, 1)//获取到当前月份,设置月份
                console.log(data.getMonth())
                let count = parseInt(data.getMonth());
                // if(data.getMonth() <= 6) {
                //     var i = 1;
                    for (let i = count; i < (count+6); i++) {
                        // data.setMonth(data.getMonth() - 1);//每次循环一次 月份值减1
                        // var m = data.getMonth() + 1;
                        i = i < 10 ? "0" + i : i;
                        this.listMonth.push({date: year + '-' + i, month: parseInt(i), children: []})
                
                    }
                // } else {
                    // var i = 7;
                    // for (let i = (data.getMonth() - 1); i < (data.getMonth() + 5); i++) {
                    //     // data.setMonth(data.getMonth() - 1);//每次循环一次 月份值减1
                    //     // var m = data.getMonth() + 1;
                    //     i = i < 10 ? "0" + i : i;
                    //     this.listMonth.push({date: year + '-' + i, month: parseInt(i), children: []})
                
                    // }
                // }
                
            this.qa.queryDatascreen9ById({}).then(res => {
                this.jiData = res.data;
                this.isTrue = true;
                this.diseaseData = this.jiData.datascreen9HealthyMonitorList;
    

                //鸡脚环散点图
                // let list = [{symbolSize: 16,type: 'scatter',color: '#27ecff', data: []},{symbolSize: 16,type: 'scatter',color: '#27ffa6', data: []},
                // {symbolSize: 16,type: 'scatter',color: '#baff27', data: []},{symbolSize: 16,type: 'scatter',color: '#ffdc33', data: []}];
                // for(let i = 0; i < this.jiData.datascreen9FootRingList.length; i++) {
                //     let count = Math.floor(Math.random()*(1000-1) + 1);
                //     if(this.jiData.datascreen9FootRingList[i] > 0 && this.jiData.datascreen9FootRingList[i] < 5000) {
                //         list[0].data.push([count, this.jiData.datascreen9FootRingList[i]]);
                //     } else if(this.jiData.datascreen9FootRingList[i] >= 5000 && this.jiData.datascreen9FootRingList[i] < 15000){
                //         list[1].data.push([count, this.jiData.datascreen9FootRingList[i]]);
                //     } else if(this.jiData.datascreen9FootRingList[i] >= 15000 && this.jiData.datascreen9FootRingList[i] < 20000) {
                //         list[2].data.push([count, this.jiData.datascreen9FootRingList[i]]);
                //     } else if(this.jiData.datascreen9FootRingList[i] >= 20000) {
                //         list[3].data.push([count, this.jiData.datascreen9FootRingList[i]]);
                //     }
                // }
                // this.$refs.jiFoot.data = list;
                // this.$refs.jiFoot.moreChart();


            })


            this.qa.queryRedisIotDataByLandIds({"landIds":168,"orgType":"DDZY"}).then(res => {
                this.iotObj = res.data.list[0];
            })

            this.qa.queryIotDataByDate({deviceType: 'SENSOR_QX', datascreenNo: '09'}).then(res => {
                    for(let i = 0; i < res.data.length; i++) {
                        if(!res.data[i].iotDataBO) {
                            res.data[i].iotDataBO = {};
                        }
                    }
                    if(res.data.length > 0) {
                        this.qxList = res.data[0];
                    }
                })

            this.qa.getEggsMix({}).then(res => {
                // this.allCount = Number(res.data.cornnum) + Number(res.data.pulpnum) + Number(res.data.stonenum) +Number(res.data.pabulumnum)
                this.kouliang = res.data;
            })

            this.qa.zwcrListLed({}).then(res => {
                this.jihuizong = res.data;
            })

            this.qa.queryEc({}).then(res => {
                this.waibuiot = res.data;
            })

            this.qa.eggsAntAndMonthListLed({}).then(res => {
                console.log(res);
                for(let i = 0; i < this.listMonth.length; i++) {
                    for(let j = 0; j < res.rows.length; j++) {
                        
                        if(this.listMonth[i].date == (res.rows[j].antTime).substring(0,7)) {
                            this.listMonth[i].children.push({
                                type: 1,
                                name: res.rows[j].vacname.split("、")
                            })
                            // this.listMonth[i].type = 1;
                            // this.listMonth[i].name = res.rows[j].vacname.split("、");
                        }
                    }
                }
            })

            this.qa.eggsCureAndMonthListLed({}).then(res => {
                console.log(res);
                for(let i = 0; i < this.listMonth.length; i++) {
                    for(let j = 0; j < res.rows.length; j++) {
                        
                        if(this.listMonth[i].date == (res.rows[j].cureTime).substring(0,7)) {
                            this.listMonth[i].children.push({
                                type: 2,
                                name: res.rows[j].medname
                            })
                        
                        }
                    }
                }
            })

            this.qa.eggsWarnAndMonthListLed({}).then(res => {
                console.log(res);
                for(let i = 0; i < this.listMonth.length; i++) {
                    for(let j = 0; j < res.rows.length; j++) {
                        
                        if(this.listMonth[i].date == (res.rows[j].warnTime).substring(0,7)) {
                            this.listMonth[i].children.push({
                                type: 3,
                                name: res.rows[j].name
                            })
                   
                        }
                        console.log(this.listMonth)
                    }
                }
            })
        },
        bllw(num){
            return parseFloat(num).toFixed(2)
        },
        changeWeiyangDay(_day, index) {
            this.currDay = _day;
            this.qa.eggsFeedListLed({feedTime: this.dateList[index]}).then(res => {
                    for(let i = 0; i < res.rows.length; i++) {
                        res.rows[i].feedAllTime = (res.rows[i].feedTime).substring(10, 16);
                    }
                    this.wyList = res.rows;
                })
        },
        changeWeiyangDay1(_day, index) {
            this.currDay1 = _day;
             this.qa.queryDatascreen9JbqHistory({beginDate: this.dateList[index]+' 00:00:00', endDate: this.dateList[index]+' 23:59:59'}).then(res => {
                   var list = [{symbolSize: 10,type: 'scatter',color: '#27ecff', data: []}];
                   for(let i = 0; i <　res.data.length; i++) {
                       if(res.data[i].sameDaySteps < 100000) {
                           list[0].data.push(res.data[i].sameDaySteps)
                       }
                    }
                    // console.log(list)
                    // alert(this.$refs.jiFoot)
                   this.$refs.jiFoot.data = list;
                    this.$refs.jiFoot.moreChart();
                })
        },
        changeWeiyangDay2(_day, index) {
            this.currDay2 = _day;
            this.qa.queryIotDataByDate({deviceType: 'SENSOR_QX', datascreenNo: '09', dateQuery: this.dateList[index]}).then(res => {
                    for(let i = 0; i < res.data.length; i++) {
                        if(!res.data[i].iotDataBO) {
                            res.data[i].iotDataBO = {};
                        }
                    }
                    if(res.data.length > 0) {
                        this.qxList = res.data[0];
                    }
                    
    
    
                })
        },
        getday7List() {
            this.currDay = new Date().getDate();
            this.currDay1 = new Date().getDate();
            this.currDay2 = new Date().getDate();
            this.day7List = [];
            this.day7List1 = [];
            this.day7List2 = [];
            for (let i = 6; i >= 0; i--) {
                this.day7List.push(this.currDay - i);
                this.day7List1.push(this.currDay1 - i);
                this.day7List2.push(this.currDay2 - i);
            }
        },
        getWeiyangRecords() {
            this.weiyangRecordList = ["5:00", "12:00", "18:00"];
        },
        childMethod(tabsType) {
            this.$parent.tabsTypeClick(tabsType);
        },
        doPlay(_url) {
            this.qa.getAccessToken({}).then(res => {
                this.player = new EZUIKit.EZUIKitPlayer({
                    autoplay: true,
                    id: "video111",
                    accessToken: res.data.accessToken,
                    url: _url,
                    template: "simple", // simple - 极简版;standard-标准版;security - 安防版(预览回放);voice-语音版；
                    // 视频上方头部控件
                    //header: ["capturePicture", "save", "zoom"], // 如果templete参数不为simple,该字段将被覆盖
                    //plugin: ['talk'],                       // 加载插件，talk-对讲
                    // 视频下方底部控件
                    // footer: ["talk", "broadcast", "hd", "fullScreen"], // 如果template参数不为simple,该字段将被覆盖
                    audio: 0, // 是否默认开启声音 0 - 关闭 1 - 开启
                    // openSoundCallBack: data => console.log("开启声音回调", data),
                    // closeSoundCallBack: data => console.log("关闭声音回调", data),
                    // startSaveCallBack: data => console.log("开始录像回调", data),
                    // stopSaveCallBack: data => console.log("录像回调", data),
                    // capturePictureCallBack: data => console.log("截图成功回调", data),
                    // fullScreenCallBack: data => console.log("全屏回调", data),
                    // getOSDTimeCallBack: data => console.log("获取OSDTime回调", data),
                    width: 3080,
                    height: 1560
                });
            });
        },

        GetTime() {
            var date = new Date();
            var base = Date.parse(date); // 转换为时间戳
            var year = date.getFullYear(); //获取当前年份
            var mon = date.getMonth() + 1; //获取当前月份
            var day = date.getDate(); //获取当前日
            var oneDay = 0;
                //var daytime = `${year}${mon >= 10 ? mon : '0' + mon}${day >= 10 ? day : '0' + day}`; //今日时间
                //this.$data.daytime = daytime; // 今日时间赋值给变量
                        
            var daytimeArr = []
            var dateArr = [];
                for (var i = 1; i <= 7 ; i++) { //前七天的时间
                    var now = new Date(base -= oneDay);
                    var myear = now.getFullYear();
                    var month = now.getMonth() + 1;
                    var mday = now.getDate()
                    daytimeArr.unshift([mday>=10?mday:'0'+mday].join('-'));
                    oneDay = 24 * 3600 *1000;
                    dateArr.unshift(myear+'-'+(month>=10?month:'0'+month)+'-'+(mday>=10?mday:'0'+mday))
                }
                
                this.daytimeArr = daytimeArr;
                this.dateList = dateArr;

                this.currDay = this.daytimeArr[this.daytimeArr.length - 1];
                this.currDay1 = this.daytimeArr[this.daytimeArr.length - 1];
                this.currDay2 = this.daytimeArr[this.daytimeArr.length - 1];

                this.qa.eggsFeedListLed({feedTime: this.dateList[this.dateList.length - 1]}).then(res => {
                    for(let i = 0; i < res.rows.length; i++) {
                        res.rows[i].feedAllTime = (res.rows[i].feedTime).substring(10, 16);
                    }
                    this.wyList = res.rows;
                })

                 this.qa.queryDatascreen9JbqHistory({beginDate: this.dateList[this.dateList.length - 1]+' 00:00:00', endDate: this.dateList[this.dateList.length - 1]+' 23:59:59'}).then(res => {
                   var list = [{symbolSize: 10,type: 'scatter',color: '#27ecff', data: []}];
                   for(let i = 0; i <　res.data.length; i++) {
                       if(res.data[i].sameDaySteps < 100000) {
                           list[0].data.push(res.data[i].sameDaySteps)
                       }
                    }
                    // console.log(list)
                    // alert(this.$refs.jiFoot)
                   this.$refs.jiFoot.data = list;
                    this.$refs.jiFoot.moreChart();
                })
        },


        transitionFun(_url, _title) {


            this.doPlay(_url);

            this.show = !this.show;
            this.vedioTitle = _title;
        },
        clickRight() {
            this.$parent.tabsTypeClick('4');
        },
        clickLeft() {
            this.$parent.tabsTypeClick('5');
        }

    }
}
</script>

<style scoped>
div.parentBoxCls {
    position: relative;
}

div.mainBoxCls {
    width: 1920px;
    height: 1080px;
    background-image: url("../../assets/images/dataScreen/qiranChickenEggRoom/main.png");
    background-size: 1895px 1050px;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    z-index: 1;
    padding-top: 100px;
    padding-left: 52px;

}

div.buttonBoxCls {
    width: 1920px;
    height: 1080px;
    background-color: #0f2240;
    background-image: url("../../assets/images/dataScreen/qiranChickenEggRoom/dibuBG.png");
    background-size: 1920px 180px;
    background-repeat: no-repeat;
    background-position: bottom center;
    position: absolute;
    z-index: 0;
}

div.leftMainCls {
    float: left;
    height: 100%;
    width: 514px;
}

div.midMainCls {

    width: 725px;
    float: left;
    height: 100%;
    margin-left: 26px;
}

div.xuanchuanshipinCls {
    height: 420px;
    background: #091A38;
    margin-top: 16px;
}

div.paihangbanCls {
    height: 190px;
    background-image: url("../../assets/images/dataScreen/qiranChickenEggRoom/zhuxitai.png");
    background-size: 718px 85px;
    background-position: bottom center;
    background-repeat: no-repeat;
    display: flex;

}

div.paihangbanCls>div {
    flex: 1;
    background-size: 120px 134px;
    background-position: center center;
    background-repeat: no-repeat;
    float: left;
}

div.huanFntCls {
    color: #dfcc50;
}

.video {
    width: 100%;
    height: 100%;
}

.treatment-icon {
    width: 14px;
    height: 13px;
}

.grid-title {
    width: 30px;
    height: 60px;
    line-height: 34px;
    text-align: center;
    font-size: 12px;
    color: #fff;
}
.grid {
    width: 78px;
    height: 185px;
    box-sizing: border-box;
    border: 1px solid #fff;
    font-size: 12px;
}
.grid-date {
    width: 78px;
    text-align: center;
    font-size: 12px;
    margin-top: 10px;
    color: #fff;
}

div.lanFntCls {
    color: #00D3DB;
}

div.paihangbanCls>div.ban1Cls {
    background-image: url("../../assets/images/dataScreen/qiranChickenEggRoom/ban1.png");
}
div.paihangbanCls>div.iot1 {
    background-image: url("../../assets/images/dataScreen/eggIot1.png");
}
div.paihangbanCls>div.ban2Cls {
    background-image: url("../../assets/images/dataScreen/qiranChickenEggRoom/ban2.png");
}
div.paihangbanCls>div.iot2 {
    background-image: url("../../assets/images/dataScreen/eggIot2.png");
}

div.paihangbanCls>div.ban3Cls {
    background-image: url("../../assets/images/dataScreen/qiranChickenEggRoom/ban3.png");
}
div.paihangbanCls>div.iot3 {
    background-image: url("../../assets/images/dataScreen/eggIot3.png");
}

div.paihangbanCls>div.ban4Cls {
    background-image: url("../../assets/images/dataScreen/qiranChickenEggRoom/ban4.png");
}
div.paihangbanCls>div.iot4 {
    background-image: url("../../assets/images/dataScreen/eggIot4.png");
}

div.paihangbanCls>div>div:nth-child(1) {
    text-align: center;
    font-size: 22px;
    font-weight: bolder;
    padding-top: 50px;
}

div.paihangbanCls>div>div:nth-child(2) {
    text-align: center;
    color: #fff;
    font-size: 12px;
    padding-top: 1px;

}

div.rightMainCls {
    float: left;
    height: 100%;
    width: 514px;
    margin-left: 26px;

}

div.dataBoxCls {
    width: 100%;
    height: 100%;
    background-color: #091A38;
    border: 1px solid #1585D5;
    position: relative;
}

div.dataBoxCls>div.jiaoCls {
    width: 32px;
    height: 32px;
    position: absolute;
    background-size: 32px 32px;
}

div.dataBoxCls>div.jiao1Cls {
    top: -4px;
    left: -4px;
    background-image: url("../../assets/images/dataScreen/qiranChickenEggRoom/jiao1.png");
}

div.dataBoxCls>div.jiao2Cls {
    top: -4px;
    right: -4px;
    background-image: url("../../assets/images/dataScreen/qiranChickenEggRoom/jiao2.png");
}

div.dataBoxCls>div.jiao3Cls {
    bottom: -4px;
    right: -4px;
    background-image: url("../../assets/images/dataScreen/qiranChickenEggRoom/jiao3.png");
}

div.dataBoxCls>div.jiao4Cls {
    bottom: -4px;
    left: -4px;
    background-image: url("../../assets/images/dataScreen/qiranChickenEggRoom/jiao4.png");
}

div.titleJtCls {
    height: 47px;
    line-height: 47px;
    position: relative;

}

div.titleJtCls>div.tbttsCls {
    display: block;
    width: 100%;
    position: absolute;
    text-align: center;
    color: #fff;
    background-color: #0c2c63;
}

div.titleJtCls>div.tbttsCls>img {
    width: 60px;
    height: 15px;
    vertical-align: middle;
    margin-left: 16px;
    margin-right: 16px;
}

div.tcnrCls {
    height: 423px;
    position: relative;
    background-color: #0e2852;
}

div.tcnrsCls {
    position: relative;
    background-color: #0e2852;
}

div.qnklxhlCls {
    color: #fff;
    font-size: 14px;
    height: 40px;
    line-height: 40px;
    text-align: center;
}

div.qnklxhlDetailCls {
    padding-left: 18px;
    height: 180px;
}

div.qnklxhlDetailBgCls {
    width: 145px;
    height: 75px;
    background-image: url("../../assets/images/dataScreen/qiranChickenEggRoom/zhibiao2.png");
    background-size: 145px 75px;
    float: left;
    margin-right: 15px;
    margin-bottom: 15px;

}
div.qnklxhlDetailBgCls2 {
    width: 305px;
    height: 75px;
    background-image: url("../../assets/images/dataScreen/qiranChickenEggRoom/zhibiao1.png");
    background-size: 305px 75px;
    float: left;
    margin-right: 15px;
    margin-bottom: 15px;

}

div.qnklxhlDetailBgCls>div:nth-child(1) {
    font-weight: bolder;
    font-size: 18px;
    padding-top: 10px;
    text-align: center;
    color: #00D3DB;

}

div.qnklxhlDetailBgCls>div:nth-child(1)>span {
    font-size: 12px;
    font-weight: normal;
}

div.qnklxhlDetailBgCls>div:nth-child(2) {
    text-align: center;
    font-size: 16px;    
    padding-top: 5px;
    color: #fff;
}
div.qnklxhlDetailBgCls>div:nth-child(3) {
    text-align: center;
    padding-top: 5px;
    color: #fff;
    font-size: 12px;
}
div.ywsjtjBoxCls {
    height: 38px;
    position: relative;
}

div.ywsjtjBoxCls>div.timerBarCls {
    position: absolute;
    width: 480px;
    height: 38px;
    line-height: 38px;
    background: #0f2f5c;
    display: flex;
    left: 50%;
    color: #fff;
    transform: translateX(-50%);
}

div.ywsjtjBoxCls>div.timerBarCls>span {
    flex: 1;
    font-size: 12px;
    display: block;
    height: 38px;
    text-align: right;
}

div.ywsjtjBoxCls>div.weishiRecordCls {
    margin-top: 20px;
    background-size: 15px 30px;
    background-position: center top;
    background-image: url("../../assets/images/dataScreen/weishiRecBg.png");
    background-repeat: no-repeat;
    position: absolute;
    color: #dfcc50;
    font-size: 12px;
    height: 60px;
    padding-top: 34px;
    position: absolute;

}

div.dayBarCls {
    height: 33px;
    line-height: 33px;
    width: 480px;
    margin-left: 16px;
    margin-top: 10px;
    display: flex;
}

div.dayBarCls>div {
    float: left;
    font-size: 12px;
    flex: 1;
    text-align: center;
    color: #fff;
    border: 1px solid #07375A;
    cursor: pointer;
}

div.dayBarCls>div:nth-child(n+2) {
    margin-left: -1px;
}

div.dayBarCls>div.seled {
    background: #3DA1FF;
}

div.wenduBoxCls {

    background-size: cover;
    background-image: url("../../assets/images/dataScreen/wenduBg.png");
    width: 150px;
    height: 150px;
    margin-top: 20px;
    margin-left: 20px;
    text-align: center;
    padding-top: 40px;
    font-size: 40px;
    font-weight: bolder;
    color: #fff;
    float: left;
}

div.wulianwangValueBoxCls {
    float: left;
    font-size: 14px;
    padding-top: 20px;
    color: #fff;

}

.echarts-icon {
    width: 14px;
    height: 14px;
    display: block;
}

div.wulianwangValueBoxCls>div {
    height: 25px;
    line-height: 25px;
    width: 300px;
    text-indent: 40px;
    background-size: 20px 20px;
    background-repeat: no-repeat;
}

div.wulianwangValueBoxCls>div>span:nth-child(1) {
    display: block;
    float: left;
}

div.wulianwangValueBoxCls>div>span:nth-child(2) {
    display: block;
    float: right;
}

div.wulianwangValueBoxCls>div:nth-child(1) {
    background-position: 0px center;
    background-image: url("../../assets/images/dataScreen/wu1.png");
    padding-left: 0px;
}

div.wulianwangValueBoxCls>div:nth-child(2) {
    background-image: url("../../assets/images/dataScreen/wu2.png");
    background-position: 25px center;
    padding-left: 25px;
}

div.wulianwangValueBoxCls>div:nth-child(3) {
    background-image: url("../../assets/images/dataScreen/wu3.png");
    background-position: 50px center;
    padding-left: 50px;
}

div.wulianwangValueBoxCls>div:nth-child(4) {
    background-image: url("../../assets/images/dataScreen/wu4.png");
    background-position: 50px center;
    padding-left: 50px;
}

div.wulianwangValueBoxCls>div:nth-child(5) {
    background-image: url("../../assets/images/dataScreen/wu5.png");
    background-position: 25px center;
    padding-left: 25px;
}

div.wulianwangValueBoxCls>div:nth-child(6) {
    background-image: url("../../assets/images/dataScreen/wu6.png");
    background-position: 0px center;
    padding-left: 0px;
}

div.vedioRowCls{
    float: left;
    margin-top: 20px;
}
div.vedioRowCls>div{
    width: 230px;
    height: 220px;
    float: left;
    margin-left: 20px;
    position: relative;
}
div.vedioColTitleCls{
    font-size: 14px;
    position: absolute;
    bottom: 0px;
    text-align: right;
    width: 100%;
    background-image: url("../../assets/images/dataScreen/vTitleBc.png");
    background-size: cover;
    height: 35px;
    line-height: 35px;
    padding-right: 10px;
    color:#fff;
}
.disease-text-1 {
    color: #00EB35;
 }
 .disease-text-2 {
    color: #E8C418;
 }
</style>