
<template>
  <div class="echarts-box">
    <div id="container" v-if="datalist"></div>

    
  </div>
</template>

<script>
import * as echarts from 'echarts';


export default {
  data(){
    return {
        datalist: []
    }
  },    
  name: "HelloWorld",
  props: {
    msg: String,
  },
  mounted() {
    // this.moreChart();
  },
  methods: {
    moreChart() {
        var xData = [];
        var yStandard = [];
        for(let i = 0; i < this.datalist.length; i++) {
            xData.unshift(this.datalist[i].lookeggTime.substring(5,10));
            let obj = {
                name: 'eee',
                value: parseInt(this.datalist[i].num),
                minAmbientTemperature: this.datalist[i].minAmbientTemperature,
                maxAmbientTemperature: this.datalist[i].maxAmbientTemperature,
                avesteps: this.datalist[i].avesteps,
            };
            yStandard.unshift(obj);
        }
        var chartDom = document.getElementById('container');
        var myChart = echarts.init(chartDom);
        var option;

        option = {
                // title: {
                //     text: '体重测定图表(单位: 公斤)',
                //     // subtext: '纯属虚构'
                //     textStyle: {//主标题文本样式{"fontSize": 18,"fontWeight": "bolder","color": "#333"}
                //         fontFamily: 'Arial, Verdana, sans...',
                //         fontSize: 14,
                //         color: '#0B1947',
                //         fontWeight: 'normal',
                //     },../../../../assets/images/dataScreen/egg/egg.png
                // },
                tooltip: {
                    trigger: 'axis',
                    textStyle: {
                        fontSize: 16
                    },
                    borderColor: 'rgba(0, 0, 0, 0)',
                    padding:[ 40, 40 ,80, 25 ],
                    extraCssText: `background:url(${require("../../../../assets/images/dataScreen/egg/eggBg.png")}) no-repeat center center ;min-width:142px;height:55px;background-size:100% 100%;`,
                    formatter: function(params) {              
                        var result = ''
                        params.forEach(function (item) {
                            console.log(item)
                            result += `
                                <div style="display:flex;margin-top:20px;">
                                    <div style="width:61px;">
                                        <div style="color: #ccc; font-size: 12px;">产蛋</div>
                                        <div style="color: #FFDC33; font-size: 15px;">
                                            <span style="font-size:15px;">${item.value}</span>
                                            <span style="font-size:12px;color:#fff;">颗</span>                                            
                                        </div>
                                    </div>
                                    <div style="width:61px;padding-left:18px;">
                                        <div style="color: #ccc; font-size: 12px;">温度</div>
                                        <div style="color: #00E634;margin-left:-13px;">
                                            <span style="font-size:15px;"><span style="display:inline-block;width:17px;font-size:15px;">${item.data.minAmbientTemperature!=''?(Number(item.data.minAmbientTemperature)).toFixed(0):''}</span>/${item.data.maxAmbientTemperature!=''?(Number(item.data.maxAmbientTemperature)).toFixed(0):''}</span>
                                            <span style="font-size:12px;color:#fff;">°C</span>
                                        </div>
                                    </div>
                                    <div style="width:61px;padding-left:22px;">
                                        <div style="color: #ccc; font-size: 12px;">日均步数</div>
                                        <div style="color: #00E634;">
                                            <span style="font-size:15px;">${item.data.avesteps}</span>
                                            <span style="font-size:12px;color:#fff;">步</span>
                                        </div>
                                    </div>
                                    <div style="width:61px;padding-left:50px;">
                                        <div style="color: #ccc; font-size: 12px;">喂食</div>
                                        <div style="color: #FFDC33;margin-left:-10px;">
                                            <span style="font-size:15px;">210</span>
                                            <span style="font-size:12px;color:#fff;">斤</span>
                                        </div>
                                    </div>
                                </div>
                            `  + "</br>"
                            // + dotHtml+ ' ' + item.seriesName + ' ' + item.data
                        })
                        return result
                    }
                },
                grid: {
                    left: 60,
                    top: 30,
                    bottom: 60,
                    right: 40
                },
                // legend: {
                //     icon: 'rectangle',
                //     data: ['标准', '测定'],
                //     right: '4%'
                // },
                toolbox: {
                    show: false,
                    textStyle: {
                        color: '#fff',
                        fontSize: 14
                    },
                    
                    feature: {
                        dataZoom: {
                            yAxisIndex: 'none'
                        },
                        dataView: {readOnly: false},
                        magicType: {type: ['line', 'bar']},
                        restore: {},
                        saveAsImage: {}
                    }
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: xData,
                    nameTextStyle :{
					    fontSize: 14
				    },
 
                    axisTick: {
                    show: false,  //刻度线
                    },

                    axisLabel: {
                        interval: 0,
                        rotate: 35,
                        margin: 20,
                        textStyle: {
                            color: '#CCCCCC',
                            fontSize: 14,
                            
                        },
                    },
                    axisLine: {
                        lineStyle: {
                            type: 'solid',
                            color: '#ccc',
                            width: 1
                        }
                    },

                },
                yAxis: {
                    type: 'value',
                    splitLine: {
                        show: false,
                        lineStyle:{
                            color: '#CCCCCC',
                            width: 1,
                            type: 'solid'
                        }
                     },
                    axisLabel: {
                        textStyle: {
                            color: '#CCCCCC',
                            fontSize: 14
                        },
                    },
                    axisLine: {
                        lineStyle: {
                        type: 'solid',
                        color: '#fff',
                            width: 1
                        }
                    },
                },
                series: [
                    {
                        name: '产蛋(颗)',
                        stack: '',
                        type: 'line',
                        data: yStandard,
                        //smooth: 2,
                        symbolSize: 12,
                        symbol:'circle',
                        color: '#3de2d6',

                        showSymbol: false,
                        lineStyle:{
                            width: 1,
                            color: '#D5CC61'
                        },
                        areaStyle: {
                            opacity: 0.8,
                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                offset: 0,
                                color: '#575A36'
                            }, {
                                offset: 1,
                                color: '#0B1834'
                            }])
                        },
                    },
                ]
        };
        option && myChart.setOption(option);
    }
  }
};
</script>

<style lang="scss"  scoped>

    .charts-box, #container {
        width: 100%;
        height: 400px;
    }
</style>